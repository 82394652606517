.contact-footer-container {
    background-color:var(--background-dark-color);
    padding: 0 10%;
    font-family: 'Poppins', sans-serif;
  }
  
  .contact-section {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  
  .contact-left, .contact-right {
    width: 48%;
  }
  
  .contact-left h2 {
    font-size: 1.8rem;
    color: var(--primary-color);
  }

  .contact-right h2{
    font-size: 1.8rem;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    line-height: 2.1rem;
    color: var(--secondary-color);
    margin-bottom: 0.4rem;
  }
  
  .contact-left p, .contact-right form input, .contact-right form textarea, .contact-right form button {
    color: #a8a8d1;
  }
  
  .contact-left p {
    margin: 1rem 0;
  }
  
  .social-icons {
    display: flex;
    gap: 1rem;
  }
  
  .social-icons a {
    color: #6c63ff;
    font-size: 1.5rem;
    transition: color 0.3s;
  }
  
  .social-icons a:hover {
    color: #fff;
  }
  
  .contact-right form {
    display: flex;
    flex-direction: column;
  }
  
  .contact-right form input, .contact-right form textarea {
    background-color: #2c2c44;
    border: none;
    border-radius: 0.5rem;
    padding: 0.8rem;
    margin-bottom: 1rem;
    color: #a8a8d1;
    font-size: 1rem;
    max-width: 572px;
    height: 62px;
  }
  .contact-right form textarea {
    resize: none;
    max-width: 572px;
    height: 98px;
  }
  
  .contact-right form button {
    background-color:var(--primary-color);
    width:174px;
    height: 50px;
    border: none;
    border-radius: 0.5rem;
    padding: 0.8rem;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .contact-right form button:hover {
    background-color: #574bff;
  }
  
  footer {
    margin-top: 2rem;
    border-top: 1px solid #2c2c44;
    padding-top: 1rem;
  }
  
  .footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }
  
  .footer-left, .footer-right {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
  .footer-right{
    margin-top: -0.8rem;
  }
  
  .footer-right p {
    color: #a8a8d1;
  }
  

  @media screen and (max-width: 641px) {
    .contact-section{
      width: 85%;
      flex-direction: column-reverse;
      justify-content: center;
      align-items: center;
    }
    .contact-left, .contact-right{
      width: 90%;
    }
    .contact-right h2{
      margin-bottom: 0.5rem;
    }

    .contact-right form input, .contact-right form textarea{
      max-width:250px;
      width: 100%;
    }

    .contact-left p{
        margin-top: -1.45rem;
    }

    .footer-content{
      padding: 0 10%;
      text-align: center;
    }
    .footer-right{
        display: grid;
        grid-template-columns: 1fr 1fr;
        place-items: center;
        gap: 1rem;
    }
    .footer-left{
      display: none;
    }
    
  }
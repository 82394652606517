.pro {
  margin: 3% 10%;
  background-color: var(--background-color);
  font-family: 'Poppins', sans-serif;
}

h1 {
  font-size: 32px;
  color: var(--primary-color);
}

p {
  color: var(--secondary-color);
  padding-top: 2rem;
}

.project img {
  width: 100%;
  border-radius: 0.5rem;
}

.project {
  margin: 2rem 0rem;
  padding: 1rem 1.5rem;
  width: 100%;
  max-width: 360px;
  background-color: #31313F;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19);
  border-radius: 0.6rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.project:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3), 0 8px 24px rgba(0, 0, 0, 0.25);
}

.project h3 {
  padding-top: 1rem;
  font-size: 24px;
  color: var(--primary-color);
  font-weight: 500;
}

.project p {
  padding: 0.7rem 0rem;
  color: var(--secondary-color);
}

.Projects {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5%;
  align-items: center;
  justify-content: center;
}

.buttons {
  display: flex;
  /* gap: 1rem; */
  justify-content:space-between;
  align-items: center;
  margin-left: 0.3rem;
  margin-right: 0.3rem;

}

.button1, .button2 {
  position: relative;
  padding: 10px 22px;
  border-radius: 6px;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
}

.button1 {
  background-color: var(--primary-color);
}

.button1:active {
  transform: scale(0.96);
}

.button1:before, .button1:after {
  position: absolute;
  content: "";
  width: 150%;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  z-index: -1;
  background-repeat: no-repeat;
}

.button1:hover:before {
  top: -70%;
  background-image: radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, var(--primary-color) 20%, transparent 30%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, var(--primary-color) 15%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
    10% 10%, 18% 18%;
  background-position: 50% 120%;
  animation: bubblesTop 0.6s ease;
}

@keyframes bubblesTop {
  0% {
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;
  }
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

.button1:hover::after {
  bottom: -70%;
  background-image: radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, var(--primary-color) 15%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 20% 20%, 18% 18%;
  background-position: 50% 0%;
  animation: bubblesBottom 0.6s ease;
}

@keyframes bubblesBottom {
  0% {
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;
  }
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%, 0% 0%, 0% 0%, 0% 0%;
  }
}

.button2 {
  outline: none;
  background-color:var(--background-color);
  border: 2px solid var(--primary-color);
  border-radius: 8px;
  gap: 5px;

}

.button2:hover {
  background-color: var(--primary-color);
  cursor: pointer;
}

.button2:active {
  transform: scale(0.96);
}

.button2:before, .button2:after {
  position: absolute;
  content: "";
  width: 150%;
  left: 50%;
  height: 100%;
  transform: translateX(-50%);
  z-index: -1;
  background-repeat: no-repeat;
}

.button2:hover:before {
  top: -70%;
  background-image: radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, transparent 20%, var(--primary-color) 20%, transparent 30%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, var(--primary-color) 15%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%);
  background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%,
    10% 10%, 18% 18%;
  background-position: 50% 120%;
  animation: bubblesTop 0.6s ease;
}

.button2:hover::after {
  bottom: -70%;
  background-image: radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, transparent 10%, var(--primary-color) 15%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%),
    radial-gradient(circle, var(--primary-color) 20%, transparent 20%);
  background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 20% 20%, 18% 18%;
  background-position: 50% 0%;
  animation: bubblesBottom 0.6s ease;
}

@media (max-width: 768px) {
  .Projects {
    flex-direction: column;
  }
}

.hero {
  background-color: var(--background-color);
  padding: 0% 10%;
}

.container {
  background-color: var(--background-color);
  font-family: 'Gabarito', cursive;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 8%;
  padding: 4% 1%;
}

.column {
  flex: 50%;
  margin-top: 2rem;
  padding: 1rem;
}

.typewritter-container {
  display: inline-block;
  width:18rem ;
  height: 19rem;
}

.column1 {
  flex: 50%;
  margin-top: 0.6rem;
  padding: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
  align-items: center;
}

h2 {
  font-family: 'Poppins';
  color: var(--primary-color);
  font-size: 3.5rem;
}

.column p{
  font-family: 'Poppins';
  color: var(--secondary-color);
  text-align: start;
  font-size: 2rem;
  padding-top: 20%;
}

.column h3 {
  font-family: 'Poppins';
  color: var(--secondary-color);
  font-weight: 500;
  padding-top: 2.5rem;
  padding-bottom: 1.5rem;
  font-size: 1.125rem;
  line-height: 1.8rem;
}

.column1 img {
  background-color: var(--primary-color);
  padding: 0%;
  width: auto;
  height: 100%;
  border-radius: 200px 0 180px 0;

}

.share {
  margin-left: 2%;
  display: flex;
  flex-direction: row;
  gap: 1.5em;
  transition: .4s ease-in-out;
  margin-top: 8rem;
}

.btn1 {
  position: relative;
  width: 4rem;
  height: 4rem;
  outline: none;
  border: none;
  border-radius: 50%;
  background-color: white;
  transition: .4s all;
}

.btn1 .instagram {
  margin-top: 0.1em;
  fill: #cc39a4;
}

.btn1 .tooltiptext1 {
  visibility: hidden;
  width: 6em;
  height: 8em;
  background-color: whitesmoke;
  color: black;
  text-align: center;
  border-radius: 10px;
  padding: 1em;
  position: absolute;
  left: -1.5em;
  top: -8em;
  z-index: 1;
  transition: .1s ease-in-out;
}

.btn1 .tooltiptext1 .card {
  width: 4em;
  height: 4em;
  background-color: white;
  cursor: pointer;
}

.btn1 .tooltiptext1 .account {
  margin-top: 1em;
}

.btn1 .tooltiptext1 .username {
  font-size: 0.8em;
  margin-top: 1.5em;
  font-weight:bolder;
}

.btn1:hover .tooltiptext1 {
  transform: translateY(-1em);
  visibility: visible;
}

.btn1:hover {
  background-color: #daa83d;
}

.btn1:hover .instagram {
  fill: white;
}

.btn2 {
  position: relative;
  width: 4rem;
  height: 4rem;
  outline: none;
  border: none;
  border-radius: 50%;
  background-color: white;
  transition: .4s all;
}

.btn2 .twitter {
  margin-top: .25em;
  margin-left: .1em;
  fill: #03A9F4;
}

.btn2 .tooltiptext2 {
  visibility: hidden;
  width: 6em;
  height: 8em;
  background-color: whitesmoke;
  color: black;
  text-align: center;
  border-radius: 10px;
  padding: 1em;
  position: absolute;
  left: -1.5em;
  top: -8em;
  z-index: 1;
  transition: .1s ease-in-out;
}

.btn2 .tooltiptext2 .card {
  width: 4em;
  height: 4em;
  background-color: white;
  cursor: pointer;
}

.btn2 .tooltiptext2 .account {
  margin-top: 1em;
}

.btn2 .tooltiptext2 .username {
  font-size: 0.8em;
  margin-top: 1.5em;
  font-weight:bolder;
}

.btn2:hover .tooltiptext2 {
  transform: translateY(-1em);
  visibility: visible;
}

.btn2:hover {
  background-color: #03A9F4;
}

.btn2:hover .twitter {
  fill: white;
}

.btn3 {
  position: relative;
  width: 4rem;
  height: 4rem;
  outline: none;
  border: none;
  border-radius: 50%;
  background-color: white;
  transition: .4s all;
}

.btn3 .tooltiptext3 {
  visibility: hidden;
  width: 6em;
  height: 8em;
  background-color: whitesmoke;
  color: black;
  text-align: center;
  border-radius: 10px;
  padding: 1em;
  position: absolute;
  left: -1.5em;
  top: -8em;
  z-index: 1;
  transition: .1s ease-in-out;
}

.btn3 .tooltiptext3 .card {
  width: 4em;
  height: 4em;
  background-color: white;
  cursor: pointer;
}

.btn3 .tooltiptext3 .account {
  margin-top: 1em;
}

.btn3 .tooltiptext3 .username {
  font-size: 0.8em;
  margin-top: 1.5em;
  font-weight:bolder;
}

.btn3:hover .tooltiptext3 {
  transform: translateY(-1em);
  visibility: visible;
}

.btn3:hover {
  background-color: black;
}

.btn3:hover .git {
  fill: white;
}

@keyframes slide-in-top {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.btn4{
  position: relative;
  width: 4rem;
  height: 4rem;
  outline: none;
  border: none;
  border-radius: 50%;
  background-color: white;
  transition: .4s all;

}

.btn4 .git {
  margin-top: .25em;
  fill: black;
}

.btn4 .tooltiptext4 {
  visibility: hidden;
  width: 6em;
  height: 8em;
  background-color: whitesmoke;
  color: black;
  text-align: center;
  border-radius: 10px;
  padding: 1em;
  position: absolute;
  left: -1.5em;
  top: -8em;
  z-index: 1;
  transition: .1s ease-in-out;
}

.btn4:hover {
  background-color: rgb(24, 140, 218);
}

.btn4 .tooltiptext4 .card {
  width: 4em;
  height: 4em;
  background-color: white;
  cursor: pointer;
}

.btn4 .tooltiptext4 .account {
  margin-top: 1em;
}

.btn4 .tooltiptext4 .username {
  font-size: 0.8em;
  margin-top: 1.5em;
  font-weight:bolder;
}

.btn4:hover .tooltiptext4 {
  transform: translateY(-1em);
  visibility: visible;
}


@media screen and (max-width: 768px) {
  .hero {
    padding: 0% 5%;
  }

  .container {
    padding: 5% 2%;
  }

  .column {
    flex: 50%;
    padding: 1.2rem;
  }

  .column1 {
    display: none;
  }


  h2 {
    font-size: 3.3rem;
  }

  .share {
    margin-top: 2rem;
    padding: 1rem;
  }

  .column p {
    font-size: 2rem;
  }

  .column h3 {
    font-size: 1.125rem;
  }
}

@media screen and (min-width: 1024px) {
  .hero {
    padding: 0% 10%;
  }
}
@import url('https://fonts.googleapis.com/css2?family=Gabarito&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.navbar {
  background-color: var(--accent-color-2);
  padding: 10px 20px;
  margin: 0;
  padding: 1% 10%;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  span{
    font-size: 2rem;
    font-family: 'Poppins';
    font-weight: 600;
    color: var(--primary-color);

  }
}

.logo img {
  height: 40px; /* Adjust height as needed */
}

.nav-links {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
}

.nav-links li {
  padding: 0% 30px;
  font-family: 'Poppins';
  font-size: 1rem;
  font-weight: 600;

}

.nav-links a {
  text-decoration: none;
  color: white;
  font-size: 1rem;
  font-weight: 500;
}

.nav-links a:hover {
  color: var(--primary-color);
  transition: 0.3s;
}

.btn {
  background-color: #ff6347; /* Adjust button color */
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.menu-icon.open .bar:nth-child(1) {
  transform: translateY(8px) rotate(45deg);
}

.menu-icon.open .bar:nth-child(2) {
  opacity: 0;
}

.menu-icon.open .bar:nth-child(3) {
  transform: translateY(-8px) rotate(-45deg);
}

.menu-icon .bar {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: transform 0.3s ease-in-out,  
  opacity 0.2s ease-in-out;
}

#resumeButton {
  background-color: var(--primary-color);
  color: white;
  padding: 10px 20px;
  font-family: 'Poppins';
  outline: none;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  border-radius: 0.8rem;
  border: none;
  animation: bounce 0.3s infinite alternate;
}
@keyframes bounce {
  to {
    transform: scale(1.1);
  }
}
#resumeButton:hover {
  opacity: 0.8;
  animation: none;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .logo span {
    font-size: 1.5rem;
    font-weight: 600;
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #333;
    padding: 10px 0;
    text-align: center;
    display: none;
  }

  .nav-links.open {
    display: flex;
    animation: CornerToCenter 0.5s forwards ease-in-out;
  }

  @keyframes CornerToCenter {
    0% {
      transform: translateX(200%);
    }
    100% {
      transform: translateX(0);
    }
  }

  .nav-links li {
    padding: 10px 0;
  }

  #resumeButton {
    display: none; /* Space between links and button */
  }

  .menu-icon {
    display: block; /* Show menu icon */
  }

  .navbar-container {
    position: relative;
  }
}

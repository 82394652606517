.skills {
  padding: 5% 10%;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: 1%;
  justify-content: center;
}

.cards img {
  width: 52px;
  height: 52px;
  margin-top: 20%;
  margin-left: 5%;
  transition: transform 0.3s ease-in-out;
}

.cards h2 {
  font-size: 24px;
  margin-left: 5%;
  margin-top: 1%;
  margin-bottom: 5%;
  font-weight: 400;
  transition: color 0.3s ease-in-out;
}

 .card{
  width: 24%;
  height: 24%;
  background-color: grey;
  border-radius: 0.6rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, background-color 0.3s ease-in-out;
}

.card:hover {
  background-color: var(--primary-color);
  transform: scale3d(1.05, 1.05, 1.05);

  h2 {
    color: var(--secondary-color);
  }

  img {
    transform: scale(1.2);
  }
}

@media screen and (max-width: 576px) {
  .card {
    width: 100%;
    height: auto;
  }
  .cards{
    gap:0.5rem;

  }
  .cards img {
    margin-top: 10%;
    width: 40px;
    height: 40px;
  }

  .cards h2 {
    font-size: 18px;
  }
}

@media screen and (min-width: 577px) and (max-width: 768px) {
  .card {
    width: 48%;
    height: auto;
  }

  .cards img {
    margin-top: 15%;
    width: 45px;
    height: 45px;
  }
  .cards{
    gap:1rem;

  }
  .cards h2 {
    font-size: 20px;
  }
}

@media screen and (min-width: 769px) and (max-width: 992px) {
  .card {
    width: 31%;
    height: auto;
  }

  .cards img {
    margin-top: 18%;
    width: 50px;
    height: 50px;
  }
  .cards{
    gap:1rem;

  }

  .cards h2 {
    font-size: 22px;
  }
}

@media screen and (min-width: 993px) {
  .card {
    width: 23%;
    height: 220px;
  }

  .cards img {
    margin-top: 20%;
    width: 52px;
    height: 52px;
  }

  .cards h2 {
    font-size: 24px;
  }
}

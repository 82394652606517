.service {
    padding: 0% 10%;
}

.service h2 {
    color: var(--primary-color);
    font-size: 2rem;
    font-weight: 600;
    line-height: 3rem;
    font-family: "Poppins";
}

.rectangle {
    display: flex;
    flex-wrap: wrap;
    font-family: 'Poppins';
    gap: 1.5%;
    align-items: center;
    justify-content: center;
    
}

.box{
    border: 2px solid var(--primary-color);
    width: 360px;
    height: 348px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 1%;
    border-radius: 1rem;
    padding: 6px;
    box-shadow: (0px 2px 4px 0px #00000033);
}

.box:nth-child(even) {
    border: 3px solid var(--secondary-color);
}

.box img {
    margin-top: 4rem;
    width: 54px;
    height: 54px;
}
.box h3 {
    color: var(--secondary-color);
    font-weight: 400;
    padding-top: 1rem;
    font-size: 24px;
}

.box p {
    color: var(--secondary-color);
    padding: 2rem 0rem;
}



@media screen and (max-width: 768px) {
    .rectangle {
        padding: 0%;
        gap: 0.2rem;
    }

    .box {
        width: 100%;
    }

    .box h3 {
        font-size: 20px;
    }

    .box p {
        font-size: 16px;
    }

    .box img {
        width: 40px;
        height: 40px;
    }

}
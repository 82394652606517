.about {
    padding: 0% 10%;
    font-family: 'Poppins';
}

.about h2 {
    font-size: 32px;
    font-weight: 500;
    color: var(--primary-color);
    margin-bottom: 1rem;
}

.about h3 {
    font-weight: 300;
    font-size: 20px;
}

.about p {
    font-size: 16px;
    font-family: 'Poppins';
    text-align: left;
    color: var(--secondary-color);
}

.about h1 {
    margin-top: .6rem;
    text-align: left;
    font-weight: bolder;
    font-size: 72px;
    color: var(--primary-color);
}

.exp {
    display: grid;
    grid-template-columns: 20% 70%;
    gap: 1rem;
    place-items: center;
}

.exp .num h1 {
    font-size: 24px;
    font-weight: 500;
    color: var(--primary-color);
    font-weight: 600;
    line-height: 144px;
    font-size: 96px;
    letter-spacing: 5px;
}

.text h3 {
    padding-top: 2rem;
    font-size: 24px;
    color: var(--secondary-color);
    font-weight: 500;
    line-height: 26px;

}

@media screen and (max-width: 576px) {
    .about {
        padding: 5%;
    }

    .about h1 {
        font-size: 3rem;
    }

    .about p {
        font-size: 1rem;
    }

    .exp {
        gap: 2rem;
        grid-template-columns: 20% 70%;
    }

    .exp .num h1 {
        font-size: 24px;
        font-weight: 600;
        font-size: 80px;
        letter-spacing: 3px;
    }

    .text h3 {
        padding-top: 1.8rem;
        font-size: 20px;
        font-weight: 500;
        line-height: 24px;
    }

}